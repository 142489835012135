<template>
  <details-card cardClass="m-0 service-schedule">
    <template v-slot:title>
      {{ $t('selectDateTime') }}
    </template>
    <template v-slot:content>
      <v-row>
        <v-col cols="12" md="6">
          <v-date-picker
            class="date-picker"
            v-model="date"
            show-current
            :allowed-dates="validDate"
            full-width
            header-color="title_highlight_color"
          />
        </v-col>
        <v-col cols="12" md="6" class="d-flex flex-column">
          <div v-if="!loading && availableTimes.hasAvailableTimes">
            <prosk-title>
              {{ $t('morning') }}
            </prosk-title>
            <div class="hours-container">
              <general-button
                v-for="time in availableTimes.morning"
                :key="time"
                size="medium"
                :secondary="selectedTime == time"
                :outlined="selectedTime !== time"
                class="hour-button"
                :action="onSelectTime(time)"
                :class="{
                  'hint_text_color--text': selectedTime !== time
                }"
              >
                {{ time }}
              </general-button>
            </div>
            <prosk-title>
              {{  $t('afternoon') }}
            </prosk-title>
            <div class="hours-container">
              <general-button
                v-for="time in availableTimes.afternoon"
                :key="time"
                size="medium"
                :secondary="selectedTime == time"
                :outlined="selectedTime !== time"
                class="hour-button"
                :action="onSelectTime(time)"
                :class="{
                  'hint_text_color--text': selectedTime !== time
                }"
              >
                {{ time }}
              </general-button>
            </div>
          </div>
          <div class="no-available-times" v-if="!loading && !availableTimes.hasAvailableTimes">
            {{  $t('noTimeAvailable') }}
          </div>
          <div v-if="loading">
            <v-skeleton-loader
              class="mb-6"
              elevation="0"
              type="article"
            />
          </div>

          <div class="confirm-btn-container">
            <general-button
              v-if="availableTimes"
              secondary
              size="medium"
              class="w-100 confirm-btn"
              :action="() => $emit('schedule', { date, time: selectedTime })"
              :disabled="!selectedTime"
            >
              {{ $t('confirmBuy') }}
            </general-button>
          </div>
        </v-col>
      </v-row>
    </template>
  </details-card>
</template>
<script>
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import ProskTitle from '@/components/socialvue/markup/ProskTitle';
import DetailsCard from '@/components/socialvue/cards/DetailsCard';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'UserServiceSchedule',
  components: {
    DetailsCard,
    GeneralButton,
    ProskTitle
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  emits: ['schedule'],
  mixins: [errorDialog],
  data () {
    return {
      loading: false,
      date: moment().format('YYYY-MM-DD'),
      availableTimes: undefined,
      selectedTime: undefined
    };
  },
  created () {
    this.getProskerAvailability(this.userId, this.date);
  },
  watch: {
    date () {
      this.getProskerAvailability(this.userId, this.date);
    }
  },
  methods: {
    parseAvailableTimes (response) {
      const formatedSchedule = response.data.data.sort((a, b) => {
        if (a.start_time < b.start_time) {
          return -1;
        }
        if (a.start_time > b.start_time) {
          return 1;
        }
        return 0;
      }).map(item => {
        item.start_time = item.start_time.slice(0, -3);
        item.end_time = item.end_time.slice(0, -3);
        return item;
      });

      const availableTimes = [];

      formatedSchedule.forEach((item) => {
        const start = moment(item.start_time, 'HH:mm');
        const end = moment(item.end_time, 'HH:mm');
        const duration = moment.duration(end.diff(start));
        const hours = duration.asHours();

        for (let i = 0; i < hours; i++) {
          const time = moment(start).add(i, 'hours').format('HH:mm');

          if (!availableTimes.includes(time)) {
            availableTimes.push(time);
          }
        }
      });

      return availableTimes.reduce((result, time) => {
        const [hour] = time.split(':');
        const key = parseInt(hour) < 12 ? 'morning' : 'afternoon';

        if (!result[key]) {
          result[key] = [];
        }

        result[key].push(time);
        result.hasAvailableTimes = true;
        return result;
      }, { morning: [], afternoon: [], hasAvailableTimes: false });
    },
    validDate (date) {
      return moment(date).isSameOrAfter(moment().toDate(), 'day');
    },
    getProskerAvailability (userId, eventDate) {
      this.loading = true;
      this.availableTimes = [];
      const url = `${process.env.VUE_APP_BASE_URL}/api/prosker-availability?date=${eventDate}&prosker_id=${userId}`;
      return axios.get(url)
        .then(response => {
          this.availableTimes = this.parseAvailableTimes(response);
        })
        .finally(() => {
          this.loading = false;
        })
        ;
    },
    onSelectTime (time) {
      return () => {
        this.selectedTime = time;
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.date-picker {
  display: flex;
  margin: auto;
  zoom: 1.5;
  max-width: 450px;

  :deep(.v-picker__title) {
    display: none;
  }

  :deep(.v-date-picker-header) {
    background-color: #6B71F2;
    font-size: 16px;

    button {
      color: white !important;
    }
  }
}

.hours-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 40px;
}

.hour-button {
  padding: 16px 32px;
}

.confirm-btn-container {
  margin-top: auto;
}

.confirm-btn {
  margin-top: 64px;
}

.no-available-times {
  flex-grow: 1;
  align-content: center;
}
</style>
