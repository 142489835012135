<template>
  <v-row class="mt-5">
    <!-- <v-col cols="12" md="5">
      <details-card>
        <template v-slot:title>
          {{ $t('notifications') }}
        </template>
        <template v-slot:content>
          <div v-if="loading">
            <v-skeleton-loader
                v-for="index in 1"
                :key="index"
                type="list-item-three-line"
            ></v-skeleton-loader>
          </div>
          <div class="notification" v-for="(notification, i) in notifications" :key="i" >
            <p class="dark_blue--text">
              {{ notification.title }}
            </p>
            <small class="time hint_text_color--text">
              {{ notification.time }}
            </small>
          </div>

          <div v-if="!loading && !notifications.length">
            {{ $t('noNotifications') }}
          </div>
        </template>
      </details-card>
    </v-col> -->

    <v-col cols="12" >
      <details-card>
        <template v-slot:title>
          <!-- {{ $t('preferences') }} -->
          {{ $t('notifications') }}
        </template>
        <template v-slot:content>
          <div v-if="loading">
            <v-skeleton-loader
                v-for="index in 3"
                :key="index"
                type="list-item-three-line, card-heading, divider"
            ></v-skeleton-loader>
          </div>

          <v-list>
            <v-list-item>
              <v-list-item-content></v-list-item-content>
              <v-list-item-action>
                <v-row>
                  <v-col class="mx-3">
                    <prosk-icon icon="mail-filled" />
                  </v-col>
                  <v-col class="mx-1">
                    <prosk-icon icon="whatsapp" />
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list-item>
            <v-list-item
                v-for="(preference, i) in notificationPreferences"
                :key="i"
            >
              <v-list-item-content>
                <v-list-item-title class="preference-title main_text_color--text">
                  {{ $t(preference.notification_name) }}
                </v-list-item-title>
                <v-list-item-subtitle class="hint_text_color--text">
                  {{ $t(preference.description) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-row>
                  <v-col>
                    <v-switch
                        @change="
                              updatePreference(
                                preference.id,
                                preferenceSelectedEmail[
                                  preference.notification_name
                                ],
                                preferenceSelectedWhatsApp[
                                  preference.notification_name
                                ]
                              )
                            "
                        v-model="
                              preferenceSelectedEmail[
                                preference.notification_name
                              ]
                            "
                        color="primary"
                        :disabled="!preference.is_editable"
                    ></v-switch>
                  </v-col>
                  <v-col>
                    <v-switch
                        @change="
                              updatePreference(
                                preference.id,
                                preferenceSelectedEmail[
                                  preference.notification_name
                                ],
                                preferenceSelectedWhatsApp[
                                  preference.notification_name
                                ]
                              )
                            "
                        v-model="
                              preferenceSelectedWhatsApp[
                                preference.notification_name
                              ]
                            "
                        color="primary"
                        :disabled="!preference.is_editable"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>
      </details-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios';

import errorDialog from '@/components/socialvue/mixins/errorDialog';
import userDataMixin from '@/mixins/userDataMixin';
import DetailsCard from '@/components/socialvue/cards/DetailsCard.vue';
import ProskIcon from '@/icons/ProskIcon.vue';

export default {
  name: 'UserReviews',
  components: { DetailsCard, ProskIcon },
  mixins: [errorDialog, userDataMixin],
  data () {
    return {
      preferenceSelectedEmail: {},
      preferenceSelectedWhatsApp: {},
      notifications: [
        // {
        //   title: 'Lorem ipsum dolor sit amet consectetur. Et duis accumsan nunc quis ipsum.',
        //   time: 'Hace 2 minutos'
        // },
        // {
        //   title: 'Lorem ipsum dolor sit amet consectetur. Et duis accumsan nunc quis ipsum.',
        //   time: 'Hace 2 minutos'
        // }
      ],
      notificationPreferences: [],
      selectedPreference: 0,
      loading: false
    };
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/currentUser'];
    }
  },
  methods: {
    getNotificationPreferences () {
      if (!this.currentUser) {
        return;
      }

      this.loading = true;
      this.notificationPreferences = [];
      const url = `${process.env.VUE_APP_BASE_URL}/api/notification-preferences?filters=user_id=${this.currentUser.id}|`;
      axios
          .get(url)
          .then((response) => {
            response.data.data.forEach((preference) => {
              this.preferenceSelectedWhatsApp[preference.notification_name] =
                  preference.whatsapp_enabled;
              this.preferenceSelectedEmail[preference.notification_name] =
                  preference.email_enabled;
            });
            this.notificationPreferences = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
    },
    updatePreference (id, emailValue, whatsAppValue) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/notification-preferences/${id}`;
      const data = {
        email_enabled: emailValue,
        whatsapp_enabled: whatsAppValue
      };
      axios
          .patch(url, data)
          .then(() => {
          })
          .catch((error) => {
            this.showError(error);
          });
    },
    sendDataLayerInfo () {
      this.trackUserData('load_content', {
        pageType: 'my_account',
        pageSubType: 'preferences'
      });
    }
  },
  mounted () {
    this.getNotificationPreferences();
    this.sendDataLayerInfo();
  }
};
</script>
<style scoped>
.notification p {
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 0;
}

.notification:not(:last-child) {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.preference-title {
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
}
</style>
