var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('details-card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('notifications'))+" ")]},proxy:true},{key:"content",fn:function(){return [(_vm.loading)?_c('div',_vm._l((3),function(index){return _c('v-skeleton-loader',{key:index,attrs:{"type":"list-item-three-line, card-heading, divider"}})}),1):_vm._e(),_c('v-list',[_c('v-list-item',[_c('v-list-item-content'),_c('v-list-item-action',[_c('v-row',[_c('v-col',{staticClass:"mx-3"},[_c('prosk-icon',{attrs:{"icon":"mail-filled"}})],1),_c('v-col',{staticClass:"mx-1"},[_c('prosk-icon',{attrs:{"icon":"whatsapp"}})],1)],1)],1)],1),_vm._l((_vm.notificationPreferences),function(preference,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"preference-title main_text_color--text"},[_vm._v(" "+_vm._s(_vm.$t(preference.notification_name))+" ")]),_c('v-list-item-subtitle',{staticClass:"hint_text_color--text"},[_vm._v(" "+_vm._s(_vm.$t(preference.description))+" ")])],1),_c('v-list-item-action',[_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"color":"primary","disabled":!preference.is_editable},on:{"change":function($event){return _vm.updatePreference(
                              preference.id,
                              _vm.preferenceSelectedEmail[
                                preference.notification_name
                              ],
                              _vm.preferenceSelectedWhatsApp[
                                preference.notification_name
                              ]
                            )}},model:{value:(
                            _vm.preferenceSelectedEmail[
                              preference.notification_name
                            ]
                          ),callback:function ($$v) {_vm.$set(_vm.preferenceSelectedEmail, 
                              preference.notification_name
                            , $$v)},expression:"\n                            preferenceSelectedEmail[\n                              preference.notification_name\n                            ]\n                          "}})],1),_c('v-col',[_c('v-switch',{attrs:{"color":"primary","disabled":!preference.is_editable},on:{"change":function($event){return _vm.updatePreference(
                              preference.id,
                              _vm.preferenceSelectedEmail[
                                preference.notification_name
                              ],
                              _vm.preferenceSelectedWhatsApp[
                                preference.notification_name
                              ]
                            )}},model:{value:(
                            _vm.preferenceSelectedWhatsApp[
                              preference.notification_name
                            ]
                          ),callback:function ($$v) {_vm.$set(_vm.preferenceSelectedWhatsApp, 
                              preference.notification_name
                            , $$v)},expression:"\n                            preferenceSelectedWhatsApp[\n                              preference.notification_name\n                            ]\n                          "}})],1)],1)],1)],1)})],2)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }